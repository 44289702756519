const fixedLayout = localStorage.getItem('FixedLayout') == 'true' ? true : false;
const hideLeftPanel = false; //localStorage.getItem('HideLeftPanel') == 'true' ? true : false;
const hideLocalVideo = localStorage.getItem('HideLocalVideo') == 'true' ? true : false;
const enableAccountFunctionality = localStorage.getItem('EnableAccountFunctionality') == 'true' ? true : false;

function userinterface(state={
	openModalID: undefined,
	currentTime: undefined,
	currentInformationPanel: 'Media',
	lastInformationPanel: 'Media',
	currentContactsTab: 'Contacts',
	currentContactsSearchTerm: '',
	currentContactsSelectedUser: {},
	isMobileDevice: false,
	fullscreenRemote: false,
	ShowQueueOverlay: true,
	fixedLayout,
	hideLeftPanel,
	hideRightOverlays: true,
	hideLocalVideo,
	localVideoIsTop: false,
	localVideoIsLeft: false,
	enableAccountFunctionality,
	outOfShowImage: '',
	screeningShowImage: '',
	studioShowImage: '',
	completedShowImage: '',
	headerLogoImage: '',
	//headerLogoImage: 'https://www.gnuralnet.com/hubfs/GNU-GeneralImages/GnuralNet%20Logo%20II%20-%20Light+Dark.png',
	CustomShowField1IsVisible: false,
	CustomShowField1Label: 'Email',
	CustomShowField2IsVisible: false,
	CustomShowField2Label: 'Phone',
	CustomShowField3IsVisible: false,
	CustomShowField3Label: 'Twitter',
	CustomShowField4IsVisible: false,
	CustomShowField4Label: 'Facebook',
	CustomShowField5IsVisible: false,
	CustomShowField5Label: 'Location'
}, action) {
	switch (action.type) {
	case 'ATTEMPT_MODAL_OPEN': {
		if (state.openModalID) {
			return state;
		}
		return {...state, openModalID: action.payload};
	}
	case 'ATTEMPT_MODAL_CLOSE': {
		if (action.payload !== state.openModalID) {
			return state;
		}
		return {...state, openModalID: undefined};
	}
	case 'UPDATE_CURRENT_TIME': {
		if (action.payload) {
			return {...state, currentTime: action.payload};
		}
		return state;
	}
	case 'UPDATE_CURRENT_INFORMATION_PANEL': {
		if (!action.payload) {
			return state;
		}
		return {...state, currentInformationPanel: action.payload, lastInformationPanel: state.currentInformationPanel};
	}
	case 'LOGIN_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_INCOMING_CALL_PENDING': {
		if (!action.payload) {
			return state;
		}
		return {...state, lastInformationPanel: state.currentInformationPanel, currentInformationPanel: 'Incoming Call'};
	}
	case 'ON_INCOMING_CALL_FULFILLED': {
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Video'};
		}
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_INCOMING_CALL_REJECTED': {
		return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
	}
	case 'ON_DROP_USER_ID_FULFILLED': {
		return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
	}
	case 'DISCONNECT_PEER_REMOTE': {
		if(state.isMobileDevice) {
			return {...state, currentInformationPanel: state.lastInformationPanel, lastInformationPanel: state.lastInformationPanel};
		}
		return state;
	}
	case 'ON_ENTER_SHOW_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media', hideLeftPanel: true};
		}
		return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media'};
	}
	case 'SET_BROWSER_INFORMATION': {
		if (!action.payload) {
			return state;
		}
		return {...state, isMobileDevice: action.payload.isMobileDevice};
	}
	case 'SET_CONTACTS_SEARCH_TERM': {
		return {...state, currentContactsSearchTerm: action.payload, currentContactsTab: (action.payload ? 'Search' : 'Contacts')};
	}
	case 'SET_CONTACTS_ACTIVE_TAB': {
		if (!action.payload) {
			return state;
		}
		return {...state, currentContactsTab: action.payload};
	}
	case 'SET_CONTACTS_SELECTED_USER': {
		if(!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentContactsSelectedUser: action.payload};
	}
	case 'ON_CALL_USER_ID_PENDING': {
		if (!action.payload || !action.payload.UserId) {
			return state;
		}
		return {...state, currentInformationPanel: 'Outgoing Call'};
	}
	case 'ON_DROP_USER_ID_PENDING': {
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'ON_CONTEXT_CHANGED': {
		if (!action.payload) {
			return state;
		}
		if (state.isMobileDevice) {
			return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media'};
		}
		return {...state, currentInformationPanel: 'Media', lastInformationPanel: 'Media'};
	}
	case 'ON_CONTEXT_DENY': {
		return {...state, currentInformationPanel: 'Media'};
	}
	case 'TOGGLE_FULLSCREEN_REMOTE': {
		return {
			...state,
			fullscreenRemote: !state.fullscreenRemote
		};
	}
	case 'TOGGLE_FIXED_LAYOUT': {
		return {
			...state,
			fixedLayout: !state.fixedLayout
		};
	}
	case 'TOGGLE_HIDE_LEFT_PANEL': {
		return {
			...state,
			hideLeftPanel: !state.hideLeftPanel
		};
	}
	case 'SET_HIDE_RIGHT_OVERLAYS': {
		return {
			...state,
			hideRightOverlays: action?.payload ? true : false
		};
	}
	case 'SET_SHOW_QUEUE_OVERLAY': {
		return {
			...state,
			ShowQueueOverlay: action.payload  || false
		};
	}
	case 'SET_OUT_OF_SHOW_IMAGE': {
		return {
			...state,
			outOfShowImage: action.payload ?? ''
		};
	}
	case 'SET_SCREENING_SHOW_IMAGE': {
		return {
			...state,
			screeningShowImage: action.payload ?? ''
		};
	}
	case 'SET_STUDIO_SHOW_IMAGE': {
		return {
			...state,
			studioShowImage: action.payload ?? ''
		};
	}
	case 'SET_COMPLETED_SHOW_IMAGE': {
		return {
			...state,
			completedShowImage: action.payload ?? ''
		};
	}
	case 'SET_HEADER_LOGO_IMAGE': {
		return {
			...state,
			headerLogoImage: action.payload ?? ''
		};
	}
	case 'SET_LOCAL_VIDEO_POSITION': {
		return {
			...state,
			localVideoIsTop: action.payload?.IsTop ?? false,
			localVideoIsLeft: action.payload?.IsLeft ?? false
		};
	}
	case 'TOGGLE_HIDE_LOCAL_VIDEO': {
		return {
			...state, 
			hideLocalVideo: !state.hideLocalVideo
		};
	}
	case 'SET_CUSTOM_SHOW_FIELD': {
		return {
			...state,
			['CustomShowField' + (action.payload.FieldNumber ?? 1) + 'IsVisible']: action.payload.IsVisible ?? false,
			['CustomShowField' + (action.payload.FieldNumber ?? 1) + 'Label']: action.payload.Label ?? ''
		};
	}
	case 'SET_ENABLE_ACCOUNT_FUNCTIONALITY': {
		return {
			...state,
			enableAccountFunctionality: action.payload ?? false
		};
	}
	case 'ON_GET_SHOW_GUEST_SITE_CONFIGURATION_FULFILLED':
	case 'ON_APPLY_CONFIGURATOR': {
		return {
			...state,
			outOfShowImage: action.payload.outOfShowImage ?? state.outOfShowImage,
			screeningShowImage: action.payload.screeningShowImage ?? state.screeningShowImage,
			studioShowImage: action.payload.studioShowImage ?? state.studioShowImage,
			completedShowImage: action.payload.completedShowImage ?? state.completedShowImage,
			headerLogoImage: action.payload.headerLogoImage ?? state.headerLogoImage,
			CustomShowField1IsVisible: action.payload.CustomShowField1IsVisible ?? state.CustomShowField1IsVisible,
			CustomShowField1Label: action.payload.CustomShowField1Label ?? state.CustomShowField1Label,
			CustomShowField2IsVisible: action.payload.CustomShowField2IsVisible ?? state.CustomShowField2IsVisible,
			CustomShowField2Label: action.payload.CustomShowField2Label ?? state.CustomShowField2Label,
			CustomShowField3IsVisible: action.payload.CustomShowField3IsVisible ?? state.CustomShowField3IsVisible,
			CustomShowField3Label: action.payload.CustomShowField3Label ?? state.CustomShowField3Label,
			CustomShowField4IsVisible: action.payload.CustomShowField4IsVisible ?? state.CustomShowField4IsVisible,
			CustomShowField4Label: action.payload.CustomShowField4Label ?? state.CustomShowField4Label,
			CustomShowField5IsVisible: action.payload.CustomShowField5IsVisible ?? state.CustomShowField5IsVisible,
			CustomShowField5Label: action.payload.CustomShowField5Label ?? state.CustomShowField5Label
		};
	}
	default:
		return state;
	}
}

export default userinterface;