const defaultState = {
	CookieGuid: '',
	ShowName: '',
	Name: '',
	Email: '',
	Phone: '',
	Twitter: '',
	Facebook: '',
	GeoLocation: '',
	Notes: '',
	ShowError: '',
	CallState: '',
	Message: '',
	ShowId: 0,
	inShow: false,
	wasInStudioQueue: false,
	wasInShow: false,
	RestrictedFields: false,
	ShowEmbedUrl: '',
	ShowQueueOverlay: true
};

function callin(state=defaultState, action) {
	switch(action.type) {
	case 'SET_JOIN_SHOW_FIELD': {
		if (!action.payload || !action.payload.fieldName) {
			return state;
		}
		return {...state, [action.payload.fieldName]: action.payload.fieldValue};
	}
	case 'ON_CONTEXT_CREATED_FULFILLED': {
		if (!action.payload || !action.payload.Context) {
			return state;
		}
		return {...state, CookieGuid: action.payload.Context, ...action.payload.CallInInformation};
	}
	case 'LOGIN_FULFILLED': {
		return {...state, 
			Email: state.Email || action.payload.Email
		};
	}
	case 'ON_ENTER_SHOW_REJECTED': {
		if (!action.error && (!action.payload || !action.payload.Error)) {
			return state;
		}
		let error = action.error || 'Failed To Join Show';
		if (action.payload && action.payload.Error && action.payload.Error.includes('Show Does Not Exists')) {
			error = 'The Show' + (action.payload.ShowName ? ' ' + action.payload.ShowName : '') + ' Does Not Exist';
		}
		return {...state, ShowError: error};
	}
	case 'ON_ENTER_SHOW_FULFILLED': {
		if (!action.payload) {
			return state;
		}
		return {...state, ShowError: '', inShow: true, ShowId: action.payload.ShowId};
	}
	case 'ON_LEAVE_SHOW_FULFILLED': {
		return {...state, CallState: '', Message: '', inShow: false};
	}
	case 'CLOSE_SHOW_FULFILLED': {
		return {...state, CallState: '', Message: '', inShow: false};
	}
	case 'ON_USER_ALERT_FULFILLED': {
		if (!action.payload || !action.payload.Code) {
			return state;
		}
		if (action.payload.Code === 'Screening' || action.payload.Code === 'Incoming') {
			return {...state, CallState: action.payload.Code, Message: action.payload.Message, wasInShow: false, wasInStudioQueue: false};
		}
		if (action.payload.Code === 'Studio') {
			return {...state, CallState: action.payload.Code, Message: action.payload.Message, wasInStudioQueue: true, wasInShow: false};
		}
		if (action.payload.Code === 'OnAir' || action.payload.Message === 'On Air') {
			// Bug in LTA that sends 'OffAir' Code and Message 'On Air' when an
			// On Air Guest Refreshes Media, This is a workaround 5/1/2019
			return {...state, CallState: 'OnAir', Message: action.payload.Message};
		}
		if (action.payload.Code === 'OffAir') {
			return {...state, CallState: action.payload.Code, Message: action.payload.Message, wasInShow: true};
		}
		return {...state, CallState: action.payload.Code, Message: action.payload.Message};
	}
	case 'ON_DISCONNECT_SELF_PENDING': {
		if (state.inShow) {
			if (state.CallState === 'OffAir' || state.CallState === 'OnAir') {
				return {
					...state,
					CallState: 'Show'
				};
			}
			return state;
		}
		return {
			...state,
			CallState: ''
		};
	}
	case 'ON_PEER_CONNECTION_DISCONNECT': {
		if (!state.inShow) {
			return {...state, CallState: ''};
		}
		return {
			...state,
			CallState: 'Show' 
		};
	}
	case 'SET_SHOW_EMBED_URL': {
		let tempURL;
		try {
			tempURL = new URL(action.payload);
		} catch {
			return {
				...state,
				ShowEmbedUrl: ''
			};
		}
		if (tempURL)
		{
			tempURL.searchParams.set('autoplay', 1);
		}
		return {
			...state, 
			ShowEmbedUrl: tempURL.toString() || action.payload || ''
		};
	}
	case 'ON_GET_SHOW_EMBED_URL_FULFILLED': {
		let tempURL;
		try {
			tempURL = new URL(action.payload.EmbedUrl);
		} catch {
			return {
				...state,
				ShowEmbedUrl: ''
			};
		}
		if (tempURL)
		{
			tempURL.searchParams.set('autoplay', 1);
		}
		return {
			...state, 
			ShowEmbedUrl: tempURL.toString() || action.payload.EmbedUrl || ''
		};
	}
	default:
		return state;
	}
}

export default callin;