import React from 'react';
import { connect } from 'react-redux';
import LoginPanel from './LoginPanel';
import MediaPanel from './MediaPanel';
//import SignupPanel from './SignupPanel';
import IncomingCallPanel from './IncomingCallPanel';
import OutgoingCallPanel from './OutgoingCallPanel';
import EditProfilePanel from './EditProfilePanel';
import JoinShowPanel from './JoinShowPanel';
import ShowButton from './ShowButton';
import Polling from '../../Polling';
import { getContextFromURL, getShowNameFromURL } from '../../../Utilities/Gnural/SessionInformation';
import { setCurrentInformationPanel, toggleFixedLayout, toggleHideLeftPanel } from '../../../Redux/Actions/userinterfaceActions';
import { setJoinShowField } from '../../../Redux/Actions/showActions';
import { setContext } from '../../../Redux/Actions/callActions';
import { logOutUser } from '../../../Redux/Actions/loginActions';
import '../../../Utilities/Gnural/LocalMediaHandler';
import style from './LeftPanel.module.css';

function mapStateToProps(store) {
	return {
		currentInformationPanel: store.userinterface.currentInformationPanel,
		isMobileDevice: store.sessionInformation.isMobileDevice,
		UserId: store.user.UserId,
		CallState: store.call.CallState,
		CallinState: store.callin.CallState,
		inShow: store.callin.inShow,
		isSafari: store.sessionInformation.isSafari,
		isChatWindowActive: store.chat.isChatWindowActive,
		isInteractionEnabled: store.polling.InteractionState ? true : false,
		DispName: store.user.DispName,
		QuestionId: store.polling.QuestionId,
		fixedLayout: store.userinterface.fixedLayout,
		hideLeftPanel: store.userinterface.hideLeftPanel && store.callin.inShow,
		enableAccountFunctionality: store.userinterface.enableAccountFunctionality
	};
}

class LeftPanel extends React.Component {
	constructor(props) {
		super(props);

		this.displayCurrentInformationPanel = this.displayCurrentInformationPanel.bind(this);

		this.onToggleFixedLayout = this.onToggleFixedLayout.bind(this);
		this.onToggleHideLeftPanel = this.onToggleHideLeftPanel.bind(this);
		this.onSetToPanel = this.onSetToPanel.bind(this);
		this.onHandleScroll = this.onHandleScroll.bind(this);
		this.onLogOutUser = this.onLogOutUser.bind(this);

		this.InnerWrapperRef = React.createRef();

		this.state = {
			displayScrollIndicatorBottom: false,
			displayScrollIndicatorTop: true 
		};
	}

	componentDidMount() {
		if (this.props.isSafari) {
			//var ignore = import('./SafariOverride.css');
		}
		
		let context = getContextFromURL();
		let showName = getShowNameFromURL();

		this.InnerWrapperRef.current.addEventListener('scroll', this.onHandleScroll);
		window.addEventListener('resize', this.onHandleScroll);
		this.onHandleScroll();

		if (context) {
			this.props.dispatch(setContext(context));
		}
		if (showName) {
			this.props.dispatch(setJoinShowField('ShowName', showName));
			this.props.dispatch(setCurrentInformationPanel('JoinShow'));
		}
		if (this.props.enableAccountFunctionality)
		{
			this.props.dispatch(setCurrentInformationPanel('Login'));
		}
	}

	componentDidUpdate() {
		this.onHandleScroll();
	}

	componentWillUnmount() {
		this.InnerWrapperRef.current.removeEventListener('scroll', this.onHandleScroll);
		window.removeEventListener('resize', this.onHandleScroll);
	}

	onHandleScroll() {
		let element = this.InnerWrapperRef.current;

		let newIndicatorTopState = (element.scrollTop != 0);
		let newIndicatorBottomState = (element.scrollHeight - element.scrollTop !== element.clientHeight);
		
		if (newIndicatorTopState !== this.state.displayScrollIndicatorTop ||
			newIndicatorBottomState != this.state.displayScrollIndicatorBottom)
		{
			this.setState({
				...this.state,
				displayScrollIndicatorBottom: newIndicatorBottomState,
				displayScrollIndicatorTop: newIndicatorTopState
			});
		}
	}

	onToggleFixedLayout() {
		this.props.dispatch(toggleFixedLayout());
	}

	onToggleHideLeftPanel() {
		this.props.dispatch(toggleHideLeftPanel());
	}
	
	onLogOutUser() {
		this.props.dispatch(logOutUser());
	}

	onSetToPanel(panel) {
		return () => {
			this.props.dispatch(setCurrentInformationPanel(panel));
		};
	}

	displayCurrentInformationPanel() {
		switch (this.props.currentInformationPanel) {
		case 'Media': {
			return (
				<React.Fragment>
					<MediaPanel/>
					{(this.props.inShow && this.props.QuestionId ?
						<div className={style.SecondaryPanel}>
							<Polling/>
						</div>
						:
						null
					)}
					<div className={'Spacer'}/>
					<div className={[style.PanelFooter, (this.state.displayScrollIndicatorTop || this.state.displayScrollIndicatorBottom ? style.Scroll : '')].join(' ')}>
						<ShowButton/>
					</div>
				</React.Fragment>
			);
		}
		case 'Login': {
			return <LoginPanel/>;
		}
		/*case 'Signup': {
			return <SignupPanel/>;
		}*/
		case 'Incoming Call': {
			return <IncomingCallPanel/>;
		}
		case 'Outgoing Call': {
			return <OutgoingCallPanel/>;
		}
		case 'JoinShow': {
			return <JoinShowPanel/>;
		}
		case 'EditProfile': {
			return <EditProfilePanel/>;
		}
		default:
			return <MediaPanel/>;
		}
	}

	render() {
		return (
			<div className={[style.LeftPanelWrapperOuter, (this.props.fixedLayout ? style.Fixed : null), (this.props.hideLeftPanel ? style.Hidden : null)].join(' ')}>
				<div className={style.LeftPanelHeader}>
					{this.props.currentInformationPanel !== 'Media' ? <div onClick={this.onSetToPanel('Media')}>Back</div> : 
						(this.props.enableAccountFunctionality && !this.props.CallinState ? 
							(this.props.UserId ?
								<React.Fragment>
									<div onClick={this.onSetToPanel('EditProfile')} className={style.Button}>Edit Profile</div>
									<div onClick={this.onLogOutUser} className={style.Button}>Log-Out</div>
								</React.Fragment>
								:
								<React.Fragment>
									<div onClick={this.onSetToPanel('Login')} className={style.Button}>Login</div>
								</React.Fragment>
							)
							:
							<div/>
						)
					}
					<div onClick={this.onToggleFixedLayout} className={[style.Button, (this.props.fixedLayout ? style.Active : '')].join(' ')}>Pin</div>
					{this.props.inShow ? <div onClick={this.onToggleHideLeftPanel} className={style.Button}>Close</div> : <div/>}
				</div>
				<div className={style.LeftPanelWrapper} ref={this.InnerWrapperRef}>
					{this.displayCurrentInformationPanel()}
					<div className={[style.ScrollShadow, style.Top, (this.state.displayScrollIndicatorTop ? style.Active : '')].join(' ')}>▲</div>
					<div className={[style.ScrollShadow, style.Bottom, (this.state.displayScrollIndicatorBottom ? style.Active : '')].join(' ')}>▼</div>
				</div>
			</div>
		);
	}
}
									
//<div onClick={this.onSetToPanel('Signup')} className={style.Button}>Sign-Up</div>

export default connect(mapStateToProps)(LeftPanel);


