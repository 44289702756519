import React from 'react';
import { connect } from 'react-redux';
import { toggleLocalMediaStreamMute } from '../../../Redux/Actions/mediaActions';
import { toggleHidePollingPanel, SetInteractionState } from '../../../Redux/Actions/pollingActions';
import { toggleHideLocalVideo } from '../../../Redux/Actions/userinterfaceActions';

import MicrophoneIcon from '../../../VectorComponents/MicrophoneIcon';
import LocalVideoIcon from '../../../VectorComponents/LocalVideoIcon';
import QuestionIcon from '../../../VectorComponents/QuestionIcon';
import HandRaiseIcon from '../../../VectorComponents/HandRaiseIcon';

import style from './MediaFooterPanel.module.css';

function mapStateToProps(store) {
	return ({
		isInteractionEnabled: store.polling.InteractionState ? true : false,
		isMuted: store.media.isMuted,
		hideLeftPanel: store.userinterface.hideLeftPanel && store.callin.inShow,
		hideLocalVideo: store.userinterface.hideLocalVideo,
		hidePollingPanel: store.polling.hidePollingPanel,
		QuestionId: store.polling.QuestionId,
		ShowId: store.callin.ShowId,
		CookieGuid: store.callin.CookieGuid,
		InteractionState: store.polling.InteractionState,
		updateIndex: store.configurator.updateIndex
	});
}

class MediaFooterPanel extends React.Component {
	constructor(props) {
		super(props);
		// Do stuff with binding
		this.onToggleLocalMediaStreamMute = this.onToggleLocalMediaStreamMute.bind(this);
		this.onToggleHideLocalVideo = this.onToggleHideLocalVideo.bind(this);
		this.onToggleHidePollingPanel = this.onToggleHidePollingPanel.bind(this);
		this.onSetInteractionState = this.onSetInteractionState.bind(this);
	}

	onToggleLocalMediaStreamMute() {
		this.props.dispatch(toggleLocalMediaStreamMute());
	}

	onToggleHideLocalVideo() {
		this.props.dispatch(toggleHideLocalVideo());
	}

	onToggleHidePollingPanel() {
		this.props.dispatch(toggleHidePollingPanel());
	}
	
	onSetInteractionState(state) {
		return () => {
			if (state === this.props.InteractionState)
			{
				this.props.dispatch(SetInteractionState(this.props.ShowId, this.props.CookieGuid, 'None'));
				return;
			}
			this.props.dispatch(SetInteractionState(this.props.ShowId, this.props.CookieGuid, state));
		};
	}

	render() {
		const surfaceColorText = 'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--Surface-Color-Text') + ')';
		const errorColor = 'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--Error-Color') + ')';
		const primaryColor = 'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--Primary-Color') + ')';
		const secondaryColor = 'rgb(' + getComputedStyle(document.documentElement).getPropertyValue('--Secondary-Color') + ')';
		return (
			<div className={style.PanelWrapper}>
				<MicrophoneIcon
					onClick={this.onToggleLocalMediaStreamMute}
					className={[style.InteractionButton, style.Error, this.props.isMuted ? style.Active : ''].join(' ')}
					Color={this.props.isMuted ? errorColor : surfaceColorText}/>
				{this.props.hideLeftPanel ? 
					<LocalVideoIcon
						onClick={this.onToggleHideLocalVideo}
						Color={this.props.hideLocalVideo ? errorColor: surfaceColorText}
						className={[style.InteractionButton, style.Error, (this.props.hideLocalVideo ? style.Active : '')].join(' ')}/>
					:
					null
				}
				{this.props.QuestionId && this.props.hideLeftPanel ?
					<QuestionIcon 
						onClick={this.onToggleHidePollingPanel}
						Color={this.props.hidePollingPanel ? surfaceColorText : primaryColor}
						className={[style.InteractionButton, style.Primary, (this.props.hidePollingPanel ? '' : style.Active)].join(' ')}/>
					:
					null
				}
				{ this.props.isInteractionEnabled ?
					<React.Fragment>
						<HandRaiseIcon
							onClick={this.onSetInteractionState('HandRaise')}
							Color={this.props.InteractionState === 'HandRaise' ? secondaryColor : surfaceColorText}
							className={[style.InteractionButton, style.Secondary, (this.props.InteractionState === 'HandRaise' ? style.Active : '')].join(' ')}/>
						<div
							onClick={this.onSetInteractionState('ThumbsUp')}
							className={[style.InteractionButton, style.Affirmative, (this.props.InteractionState === 'ThumbsUp' ? style.Active : '')].join(' ')}>
							<div>✓</div>
						</div>
						<div
							onClick={this.onSetInteractionState('ThumbsDown')}
							className={[style.InteractionButton, style.Error, (this.props.InteractionState === 'ThumbsDown' ? style.Active : '')].join(' ')}>
							<div>✕</div>
						</div>
					</React.Fragment>
					:
					null
				}
			</div>
		);
	}
}
//<button className='custom-secondary-button' onClick={this.openSettingsModal}> Settings </button>

export default connect(mapStateToProps)(MediaFooterPanel);
