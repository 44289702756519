import GSocket from '../../Utilities/Gnural/websockets';
import ExpandedFeatures from '../../ExpandedFeatures.json';

export function setDefaultStyle() {
	return  {
		type: 'SET_DEFAULT_STYLE'
	};
}

export function resetConfigurator() {
	return {
		type: 'ON_RESET_CONFIGURATOR'
	};
}

export function setConfiguratorRGBField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0 && value <= 255)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorScalarField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorAlphaField(fieldName, value) 
{
	return (dispatch) => {
		if (value >= 0 || value <= 1.0)
		{
			return dispatch({
				type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
				payload: {
					fieldName,
					value,
				}
			});
		}
		return dispatch({type: 'ON_SET_CONFIGURATOR_FIELD_REJECTED'});
	};
}

export function setConfiguratorTextField(fieldName, value) 
{
	return (dispatch) => {
		return dispatch({
			type: 'ON_SET_CONFIGURATOR_FIELD_FULFILLED',
			payload: {
				fieldName,
				value,
			}
		});
	};
}

export function toggleConfiguratorShowFieldVisibility(fieldNumber)
{
	return (dispatch) => {
		if (fieldNumber > 0 && fieldNumber <= 5)
		{
			return dispatch({
				type: 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_FULFILLED',
				payload: fieldNumber
			});
		}
		return dispatch({
			type: 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_REJECTED',
		});
	};
}

export function toggleIsConfiguratorPinned()
{
	return {
		type: 'TOGGLE_IS_CONFIGURATOR_PINNED'
	};
}

export function applyConfigurator()
{
	return {
		type: 'ON_APPLY_CONFIGURATOR'
	};
}

export function setConfiguratorShowName(value) {
	return {
		type: 'ON_SET_CONFIGURATOR_SHOW_NAME',
		payload: value
	};
}

export function setConfiguratorVisibility(value) {
	return {
		type: 'ON_SET_CONFIGURATOR_VISIBILITY',
		payload: value ?? false
	};
}

export function getShowGuestSiteConfiguration(ShowName)
{
	if (!ExpandedFeatures.UseExpandedFeatures) return () => {};
	return (dispatch) => {
		GSocket.websocket.publish('GetShowGuestSiteConfiguration', {
			ShowName
		});
		dispatch({
			type: 'ON_GET_SHOW_GUEST_SITE_CONFIGURATION_PENDING',
			payload: {
				ShowName
			}
		});
	};
}

export function setSelectedGuestSite(GuestSite)
{
	return {
		type: 'SET_SELECTED_GUEST_SITE',
		payload: GuestSite
	};
}

export function setSelectedGuestSiteConfiguration(GuestSiteConfigurationId)
{
	return {
		type: 'SET_SELECTED_GUEST_SITE_CONFIGURATION',
		payload: GuestSiteConfigurationId
	};
}

export function setSelectedGuestSiteConfigurationName(GuestSiteConfigurationName)
{
	return {
		type: 'SET_SELECTED_GUEST_SITE_CONFIGURATION_NAME',
		payload: GuestSiteConfigurationName
	};
}

export function setSelectedGuestSiteConfigurationJson(GuestSiteConfigurationJson)
{
	return {
		type: 'SET_SELECTED_GUEST_SITE_CONFIGURATION_JSON',
		payload: GuestSiteConfigurationJson
	};
}

export function getAllGuestSiteConfigurations() {
	if (!ExpandedFeatures.UseExpandedFeatures) return () => {};
	return (dispatch) => {
		GSocket.websocket.publish('getallguestsiteconfigurations', {});
		dispatch({
			type: 'ON_GET_ALL_GUEST_SITE_CONFIGURATIONS_PENDING'
		});
	};
}

export function createOrUpdateGuestSiteConfiguration(guestSiteConfiguration) {
	if (!ExpandedFeatures.UseExpandedFeatures) return () => {};
	return (dispatch) => {
		GSocket.websocket.publish('createorupdateguestsiteconfiguration', guestSiteConfiguration);
		dispatch({
			type: 'ON_CREATE_OR_UPDATE_GUEST_SITE_CONFIGURATION_PENDING'
		});
	};
}

export function deleteGuestSiteConfiguration(guestSiteConfiguration) {
	if (!ExpandedFeatures.UseExpandedFeatures) return () => {};
	return (dispatch) => {
		GSocket.websocket.publish('deleteguestsiteconfiguration', guestSiteConfiguration);
		dispatch({
			type: 'ON_CREATE_OR_UPDATE_GUEST_SITE_CONFIGURATION_PENDING'
		});
	};
}

export function setShowGuestSiteConfiguration(ShowId, ConfigurationId)
{
	if (!ExpandedFeatures.UseExpandedFeatures) return () => {};
	return (dispatch) => {
		GSocket.websocket.publish('setshowguestsiteconfiguration', {
			ShowId,
			ConfigurationId
		});
		dispatch({
			type: 'ON_SET_SHOW_GUEST_SITE_CONFIGURATION_PENDING'
		});
	};
}