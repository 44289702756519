function configurator(state= {
	DefaultStyle: {},
	showWebsiteConfigurator: false,
	isAdvancedFeaturesUser: false,
	isConfiguratorPinned: true,
	guestSiteConfigurations: {},
	selectedGuestSiteConfiguration: null,
	clientSite: window.location.host,
	showName: '',
	lightScalar: 0,
	veryLightScalar: 0,
	darkScalar: 0,
	primaryColorR: 0,
	primaryColorG: 0,
	primaryColorB: 0,
	primaryColorTextR: 0,
	primaryColorTextG: 0,
	primaryColorTextB: 0,
	secondaryColorR: 0,
	secondaryColorG: 0,
	secondaryColorB: 0,
	secondaryColorTextR: 0,
	secondaryColorTextG: 0,
	secondaryColorTextB: 0,
	surfaceColorR: 0,
	surfaceColorG: 0,
	surfaceColorB: 0,
	surfaceColorTextR: 0,
	surfaceColorTextG: 0,
	surfaceColorTextB: 0,
	errorColorR: 0,
	errorColorG: 0,
	errorColorB: 0,
	errorColorTextR: 0,
	errorColorTextG: 0,
	errorColorTextB: 0,
	affirmativeColorR: 0,
	affirmativeColorG: 0,
	affirmativeColorB: 0,
	affirmativeColorTextR: 0,
	affirmativeColorTextG: 0,
	affirmativeColorTextB: 0,
	backgroundColorR: 0,
	backgroundColorG: 0,
	backgroundColorB: 0,
	borderColorR: 0,
	borderColorG: 0,
	borderColorB: 0,
	alphaLevel: 0,
	panelAlphaLevel: 0,
	shadowColorR: 0,
	shadowColorG: 0,
	shadowColorB: 0,
	shadowAlpha: 0,
	borderAlpha: 0,
	standbyColorR: 0,
	standbyColorG: 0,
	standbyColorB: 0,
	screeningColorR: 0,
	screeningColorG: 0,
	screeningColorB: 0,
	offAirColorR: 0,
	offAirColorG: 0,
	offAirColorB: 0,
	onAirColorR: 0,
	onAirColorG: 0,
	onAirColorB: 0,
	offAirColorTextR: 0,
	offAirColorTextG: 0,
	offAirColorTextB: 0,
	onAirColorTextR: 0,
	onAirColorTextG: 0,
	onAirColorTextB: 0,
	outOfShowImage: '',
	screeningShowImage: '',
	studioShowImage: '',
	completedShowImage: '',
	headerLogoImage: '',
	CustomShowField1IsVisible: false,
	CustomShowField1Label: 'Email',
	CustomShowField2IsVisible: false,
	CustomShowField2Label: 'Phone',
	CustomShowField3IsVisible: false,
	CustomShowField3Label: 'Twitter',
	CustomShowField4IsVisible: false,
	CustomShowField4Label: 'Facebook',
	CustomShowField5IsVisible: false,
	CustomShowField5Label: 'Location',
	updateIndex: 0
}, action) {
	switch (action.type) {
	case 'ON_RESET_CONFIGURATOR': {
		let parsedJson = {};
		if (state.selectedGuestSiteConfiguration?.ConfigurationJson)
		{
			try {
				parsedJson = JSON.parse(state.guestSiteConfigurations[state.selectedGuestSiteConfiguration.ConfigurationId].ConfigurationJson);
			} catch { 
				parsedJson = {};
			}
			return {
				...state,
				...parsedJson,
				selectedGuestSiteConfiguration: state.guestSiteConfigurations[state.selectedGuestSiteConfiguration.ConfigurationId]
			};
		}
		return {
			...state,
			...parsedJson
		};
	}
	case 'ON_SET_CONFIGURATOR_FIELD_FULFILLED': {
		if (!state.selectedGuestSiteConfiguration)
		{
			return state;
		}

		// eslint-disable-next-line
		const {showWebsiteConfigurator, isAdvancedFeaturesUser, isConfiguratorPinned, guestSiteConfigurations, selectedGuestSiteConfiguration, clientSite, updateIndex, showName, loginStatus, DefaultStyle, ...visualProperties} = state;
		if (visualProperties)
		{
			visualProperties[action.payload.fieldName] = action.payload.value;
		}
		let stringifiedJson = '';
		try {
			stringifiedJson = JSON.stringify(visualProperties);
		} catch {
			stringifiedJson = '';
		}

		if (stringifiedJson)
		{
			return {
				...state,
				[action.payload.fieldName]: action.payload.value,
				selectedGuestSiteConfiguration: {
					...state.selectedGuestSiteConfiguration,
					ConfigurationJson: stringifiedJson
				}
			};
		}
		return {
			...state,
			[action.payload.fieldName]: action.payload.value,
		};

	}
	case 'ON_TOGGLE_CONFIGURATOR_SHOW_FIELD_VISIBILITY_FULFILLED': {
		if (!state.selectedGuestSiteConfiguration)
		{
			return state;
		}

		// eslint-disable-next-line
		const {showWebsiteConfigurator, isAdvancedFeaturesUser, isConfiguratorPinned, guestSiteConfigurations, selectedGuestSiteConfiguration, clientSite, updateIndex, showName, loginStatus, ...visualProperties} = state;
		if (visualProperties)
		{
			visualProperties['CustomShowField' + action.payload + 'IsVisible'] = !state['CustomShowField' + action.payload + 'IsVisible'];
		}
		let stringifiedJson = '';
		try {
			stringifiedJson = JSON.stringify(visualProperties);
		} catch {
			stringifiedJson = '';
		}

		if (stringifiedJson)
		{
			return {
				...state,
				['CustomShowField' + action.payload + 'IsVisible']: !state['CustomShowField' + action.payload + 'IsVisible'],
				selectedGuestSiteConfiguration: {
					...state.selectedGuestSiteConfiguration,
					ConfigurationJson: stringifiedJson
				}
			};
		}
		return {
			...state,
			['CustomShowField' + action.payload + 'IsVisible']: !state['CustomShowField' + action.payload + 'IsVisible']
		};
	}
	case 'ON_SET_CONFIGURATOR_SHOW_NAME': {
		return {
			...state,
			showName: action.payload
		};
	}
	case 'ON_SET_CONFIGURATOR_VISIBILITY': {
		return {
			...state,
			showWebsiteConfigurator: action.payload
		};
	}
	case 'ON_GET_SHOW_GUEST_SITE_CONFIGURATOR': 
	{
		if (!action.payload || (typeof action.payload !== 'object'))
		{
			return state;
		}
		return {
			...state,
			...action.payload
		};
	}
	case 'ON_CHECK_ADVANCED_FEATURES_USER_FULFILLED': {
		return {
			...state,
			isAdvancedFeaturesUser: true
		};
	}
	case 'TOGGLE_IS_CONFIGURATOR_PINNED': {
		return {
			...state,
			isConfiguratorPinned: !state.isConfiguratorPinned
		};
	}
	case 'ON_CREATE_OR_UPDATE_GUEST_SITE_CONFIGURATION_FULFILLED': {
		if (!action.payload || !action.payload || !action.payload.ConfigurationId)
		{
			return state;
		}
		if (state.selectedGuestSiteConfiguration)
		{
			return {
				...state,
				guestSiteConfigurations: {
					...state.guestSiteConfigurations,
					[action.payload.ConfigurationId]: action.payload
				}
			};
		}
		
		return {
			...state,
			guestSiteConfigurations: {
				...state.guestSiteConfigurations,
				[action.payload.ConfigurationId]: action.payload
			},
			selectedGuestSiteConfiguration: action.payload
		};
	}
	case 'ON_GET_ALL_GUEST_SITE_CONFIGURATIONS_FULFILLED': {
		let indexedConfigurations = {};
		if (action.payload.Lst)
		{
			action.payload.Lst.forEach(configuration => {
				if (configuration?.ConfigurationId)
				{
					indexedConfigurations[configuration.ConfigurationId] = configuration;
				}
			});
		}
		let newSelectedGuestSiteConfiguration = null;
		if (state.selectedGuestSiteConfiguration?.ConfigurationId && indexedConfigurations[state.selectedGuestSiteConfiguration.ConfigurationId])
		{
			newSelectedGuestSiteConfiguration = indexedConfigurations[state.selectedGuestSiteConfiguration.ConfigurationId];
		} else if (Object.values(indexedConfigurations)[0]) {
			newSelectedGuestSiteConfiguration = Object.values(indexedConfigurations)[0];
		}
		
		let jsonConfiguration = {};
		try {
			jsonConfiguration = JSON.parse(newSelectedGuestSiteConfiguration?.ConfigurationJson);
		} catch {
			jsonConfiguration = {};
		}

		return {
			...state,
			...state.DefaultStyle,
			guestSiteConfigurations: indexedConfigurations,
			selectedGuestSiteConfiguration: newSelectedGuestSiteConfiguration,
			...jsonConfiguration
		};
	}
	case 'ON_DELETE_GUEST_SITE_CONFIGURATION_FULFILLED': {
		if (!action.payload?.ConfigurationId)
		{
			return state;
		}
		let newSelectedGuestSiteConfiguration = state.selectedGuestSiteConfiguration;
		if (action.payload.ConfigurationId === state.selectedGuestSiteConfiguration?.ConfigurationId)
		{
			newSelectedGuestSiteConfiguration = Object.values(state.guestSiteConfigurations).find(
				configuration => configuration.ConfigurationId !== action.payload?.ConfigurationId
			) ?? null;
		}

		let newGuestSiteConfigurations = {...state.guestSiteConfigurations};
		if (state.guestSiteConfigurations[action.payload.ConfigurationId])
		{
			delete newGuestSiteConfigurations[action.payload.ConfigurationId];
		}

		let jsonConfiguration = {};
		try {
			jsonConfiguration = JSON.parse(newSelectedGuestSiteConfiguration?.ConfigurationJson);
		} catch {
			jsonConfiguration = {};
		}

		return {
			...state,
			...state.DefaultStyle,
			guestSiteConfigurations: newGuestSiteConfigurations,
			selectedGuestSiteConfiguration: newSelectedGuestSiteConfiguration,
			...jsonConfiguration
		};
	}
	case 'SET_SELECTED_GUEST_SITE_CONFIGURATION': {
		if (!action.payload || !state.guestSiteConfigurations[action.payload])
		{
			return state;
		}
		
		let jsonConfiguration = {};
		try {
			jsonConfiguration = JSON.parse(state.guestSiteConfigurations[action.payload]?.ConfigurationJson ?? '');
		} catch {
			jsonConfiguration = {};
		}

		return {
			...state,
			...state.DefaultStyle,
			selectedGuestSiteConfiguration: {...state.guestSiteConfigurations[action.payload]},
			...jsonConfiguration
		};
	}
	case 'SET_SELECTED_GUEST_SITE_CONFIGURATION_NAME': {
		if (state.selectedGuestSiteConfiguration?.ConfigurationId === 0)
		{
			return state;
		}
		return {
			...state,
			selectedGuestSiteConfiguration: {
				...state.selectedGuestSiteConfiguration,
				ConfigurationName: action.payload
			}
		};
	}
	case 'SET_SELECTED_GUEST_SITE_CONFIGURATION_JSON': {
		if (state.selectedGuestSiteConfiguration?.ConfigurationId === 0)
		{
			return state;
		}
		return {
			...state,
			selectedGuestSiteConfiguration: {
				...state.selectedGuestSiteConfiguration,
				ConfigurationJson: action.payload
			}
		};
	}
	case 'ON_CREATE_OR_UPDATE_GUEST_SITE_CONFIGURATION_PENDING': {
		if (!state.selectedGuestSiteConfiguration?.ConfigurationJson)
		{
			return state;
		}
		let parsedJson = {};
		try {
			parsedJson = JSON.parse(state.selectedGuestSiteConfiguration.ConfigurationJson);
		} catch {
			parsedJson = {};
		}

		return {
			...state,
			...parsedJson
		};
	}
	case 'ON_GET_SHOW_GUEST_SITE_CONFIGURATION_FULFILLED':
	case 'ON_APPLY_CONFIGURATOR':
	{
		return {
			...state,
			updateIndex: state.updateIndex + 1
		};
	}
	case 'SET_DEFAULT_STYLE':
	{
		return {
			...state,
			DefaultStyle: action.payload
		};
	}
	default: return state;
	}
} 

export default configurator;