import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reduxStore from './Redux/store';
import App from './Application/App';
import registerServiceWorker from './registerServiceWorker';
import './Utilities/Gnural/websockets'; 
import { AcquireBrowserInfo} from './Utilities/Gnural/SessionInformation';
//import './index.css';

if (AcquireBrowserInfo())
{
	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(<Provider store={reduxStore}><App/></Provider>);
	registerServiceWorker();
}