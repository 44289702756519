import { getConfIdFromURL } from '../../Utilities/Gnural/SessionInformation';
import ExpandedFeatures from '../../ExpandedFeatures.json';
let GSocket = undefined;

const loginMiddleware = store => next => async action => {
	if (!GSocket) {
		GSocket = (await import('../../Utilities/Gnural/websockets')).default;
	}
	switch (action.type) {
	case 'LOGIN_FULFILLED': {
		if (!action.payload || !action.payload.UserId) {
			return next(action);
		}
		GSocket.websocket.publish('GetAvatar', {UserId: action.payload.UserId});
		GSocket.websocket.publish('GetAddressBook', {UserId: action.payload.UserId});
		let state = store.getState();
		if (state.configurator.showWebsiteConfigurator && ExpandedFeatures.UseExpandedFeatures)
		{
			GSocket.websocket.publish('CheckAdvancedFeaturesUser', {UserId: action.payload.UserId});
		}
		return next(action);
	}
	case 'ON_CHECK_ADVANCED_FEATURES_USER_FULFILLED': {
		if (ExpandedFeatures.UseExpandedFeatures)
		{
			GSocket.websocket.publish('getallguestsiteconfigurations', {});
		}
		return next(action);
	}
	case 'WEBSOCKET_CONNECTION_FULFILLED': {
		const ConfId = getConfIdFromURL();
		if (ConfId) {
			GSocket.websocket.publish('FinishSignUp', {ReqId: ConfId});
		}
		return next(action);
	}
	case 'ON_ENTER_SHOW_FULFILLED':
	case 'ON_ENTER_SHOW_EXTERNAL_FULFILLED':
	case 'ON_USER_ALERT_FULFILLED': {
		let state = store.getState();
		// Different Behaviors between GSignalV1 and GSignalV2
		if (ExpandedFeatures.UseExpandedFeatures)
		{
			if (action.payload && action.payload.ShowId) {
				GSocket.websocket.publish('GetCurrentPollingQuestion', {
					ShowId: action.payload.ShowId,
					ShowName: state.callin.ShowName,
					CookieGuid: state.callin.CookieGuid
				});
			} else {
				if (state.callin.ShowId) {
					GSocket.websocket.publish('GetCurrentPollingQuestion', {
						ShowId: state.callin.ShowId,
						ShowName: state.callin.ShowName,
						CookieGuid: state.callin.CookieGuid
					});
				}
			}
		} else {
			if (action.payload && action.payload.ShowId) {
				GSocket.websocket.publish('GetCurrentPollingQuestion', {
					ShowName: state.callin.ShowName,
					CookieGuid: state.callin.CookieGuid
				});
			} else {
				if (state.callin.ShowId) {
					GSocket.websocket.publish('GetCurrentPollingQuestion', {
						ShowName: state.callin.ShowName,
						CookieGuid: state.callin.CookieGuid
					});
				}
			}
		}
		if (action.payload && action.payload.ShowName && ExpandedFeatures.UseExpandedFeatures) {
			GSocket.websocket.publish('GetShowEmbedUrl', {
				ShowName: state.callin.ShowName
			});
		} else {
			if (state.callin.ShowName && ExpandedFeatures.UseExpandedFeatures) {
				GSocket.websocket.publish('GetShowEmbedUrl', {
					ShowName: state.callin.ShowName
				});
			}
		}
		return next(action);
	}
	default:
		return next(action);
	}
};

export default loginMiddleware;